import {Col, Row} from 'react-bootstrap';

export const ShowCustomerInformation = ({customerData, buyer}) => {
  return (
    <>
      {customerData.contact?.name && (
        <Row>
          <Col>
            <span>Company: </span>
            <span className="float-right">{customerData.contact?.name}</span>
          </Col>
        </Row>
      )}
      <Row>
        <Col>
          <span>{customerData.contact?.contactName}</span>
          {customerData.address?.countryCode === 'GB' && (
            <span className="float-right">
              {customerData.address?.number} {customerData.address?.street}{' '}
              {customerData.address?.numberAddition}
            </span>
          )}
          {customerData.address?.countryCode !== 'GB' && (
            <span className="float-right">
              {customerData.address?.street} {customerData.address?.number}{' '}
              {customerData.address?.numberAddition}
            </span>
          )}
        </Col>
      </Row>
      <Row>
        <Col>
          <span>{customerData.contact?.phone} </span>
          <span className="float-right">
            {customerData.address?.zip} {customerData.address?.place}
          </span>
        </Col>
      </Row>
      <Row>
        <Col>
          <span>{customerData.contact?.email} </span>
          <span className="float-right">{customerData.address?.country}</span>
        </Col>
      </Row>

      {buyer && customerData.contact?.name && (
        <>
          <hr />
          <Row>
            <Col>
              <span>Chamber of Commerce number: </span>
              <span className="float-right">
                <strong>{customerData.chamberOfCommerceNumber}</strong>
              </span>
            </Col>
          </Row>
          <Row>
            <Col>
              <span>VAT number: </span>
              <span className="float-right">
                <strong>{customerData.vatNumber}</strong>
              </span>
            </Col>
          </Row>
          <Row>
            <Col>
              <span>Reference: </span>
              <span className="float-right">
                <strong>{customerData.reference}</strong>
              </span>
            </Col>
          </Row>
        </>
      )}
    </>
  );
};
